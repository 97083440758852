export const Projects = [
  {
    thumbnail: "./images/sppms.png",
    title: "Project Progress Management System",
    tags: ["React", "Redux", "Firebase", "CSS", "MaterializeCSS"],
    paragraph:
      "This was my final year software project and it is a system for monitoring projects' progress of final year students. It has three levels of users i.e Students, Supervisors and the Project Coordinator. The output of the system is a list of students who have completed their projects.",
    codelink: "https://github.com/chaoocharles/sppms",
    projectlink: "https://scit-sppms.web.app/",
    projectLinkText: "View Project",
  },
  {
    thumbnail: "./images/mern.jpg",
    title: "Todo-App With Auth (Free Course)",
    tags: ["MongoDB", "Express", "React", "Node", "Redux", "Material-UI"],
    paragraph:
      "This is a simple Todo-App with CRUD operations and authentication I developed for educational purposes on my YouTube channel. It helped me to teach everything you need to know about the MERN stack to start creating full-blown web applications",
    codelink: "https://github.com/chaoocharles/mern-stack",
    projectlink: "https://youtu.be/KNZZZ3pbqco",
    projectLinkText: "View Course",
  },
  {
    thumbnail: "./images/rtk.png",
    title: "Complete Shopping Cart (Free Course)",
    tags: ["React", "ReduxToolkit", "Node", "CSS"],
    paragraph:
      "This is a complete and functional shopping cart I developed with a goal of using it to teach state management in ReduxToolkit (Now the standard way of working with redux)",
    codelink: "https://github.com/chaoocharles/complete-shopping-cart",
    projectlink: "https://youtu.be/WenErzbaF1M",
    projectLinkText: "View Course",
  },
];
