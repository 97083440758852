import {
  BannerContent,
  StyledTopBanner,
} from "../styles/layout/TopBanner.styled";

export const TopBanner = () => {
  return (
    <StyledTopBanner>
      <BannerContent>
        <a href="mailto:chaoocharles@gmail.com">👻 chaoocharles@gmail.com</a>
      </BannerContent>
      <BannerContent showOnMobile>
        <a href="https://chaoocharles.gumroad.com/l/react-portfolio-course" target="_blank" rel="noreferrer">
          🔥 Learn how to Create a Portfolio like this!
        </a>
      </BannerContent>
      <BannerContent>
        <span>⚡ Available for Hire</span>
      </BannerContent>
    </StyledTopBanner>
  );
};
