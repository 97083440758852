import { Link } from "react-router-dom";

import styled from "styled-components";
import moment from "moment";

const PostCard = ({ post }) => {
  const { title, slug } = post.fields;

  return (
    <StyledPostCard>
      <Link to={`/source-codes/${slug}`}>
        <h2>{title}</h2>
      </Link>
      <p>Updated {moment(post.sys.updatedAt).fromNow()}</p>
    </StyledPostCard>
  );
};

export default PostCard;

const StyledPostCard = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid #30363d;

  h2 {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }

  p {
    color: #585e64;
    font-size: 14px;
    margin-top: 0.5rem;
    font-family: "JetBrains Mono", monospace;
  }
`;
