import { useRef } from "react";
import {
  ContactInfo,
  ImageWrapper,
  DetailsWrapper,
  StyledContactSection,
  StyledContactForm,
} from "../styles/sections/ContactSection.styled";
import { SectionHeading } from "../styles/common/SectionHeading.styled";
import { FaEnvelope, FaTwitter } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

export const ContactSection = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lz4hg69",
        "template_5vh6jir",
        form.current,
        "bUjjVX4rv7P5RZBPR"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          toast.success("Message sent 👌");
        },
        (error) => {
          console.log(error.text);
          toast.error("Failed! Message not sent 😒 Email me directly instead");
        }
      );
  };

  return (
    <>
      <StyledContactSection id="contact">
        <SectionHeading>
          <h1>Contact</h1>
          <p>Get in touch</p>
        </SectionHeading>
        <DetailsWrapper>
          <ContactInfo>
            <p>
              I'd love if you reached out to me. Even if it's just to say
              "Hey!". Don't hesitate! Drop me a line and I’ll get back to you
              ASAP!
            </p>
            <p>
              <i>
                For gig and advertising opportunities, begin your subject with
                <strong>"Gig"</strong>
              </i>
            </p>
            <div>
              <a href="mailto:chaoocharles@gmail.com">
                <FaEnvelope /> <span>chaoocharles@gmail.com</span>
              </a>
              <a
                href="https://twitter.com/ChaooCharles"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter /> <span>Twitter</span>
              </a>
            </div>
            <StyledContactForm>
              <form ref={form} onSubmit={sendEmail} autoComplete="off">
                <h3>Write me a Message 👇</h3>
                <label>Your Name</label>
                <input type="text" name="user_name" required />
                <label>Your Email</label>
                <input type="email" name="user_email" required />
                <label>Message</label>
                <textarea name="message" required />
                <input type="submit" value="Send" />
              </form>
            </StyledContactForm>
          </ContactInfo>
          <ImageWrapper>
            <img src="./images/chat.svg" alt="contact" />
          </ImageWrapper>
        </DetailsWrapper>
      </StyledContactSection>
      <hr />
    </>
  );
};
