import { Link } from "react-router-dom";

import styled from "styled-components";
import { NavLink } from "../styles/layout/Nav.styled";
import { FaYoutube, FaTwitter, FaDiscord } from "react-icons/fa";

const SimpleNav = () => {
  return (
    <StyledSimpleNav>
      <StyledNav>
        <Link to="/">Chaoo</Link>
        <Socials>
          <NavLink
            href="https://www.youtube.com/c/chaoocharles"
            target="_blank"
          >
            <FaYoutube />
          </NavLink>
          <NavLink href="https://twitter.com/ChaooCharles" target="_blank">
            <FaTwitter />
          </NavLink>
          <NavLink href="https://discord.gg/WGqJ2CZebb" target="_blank">
            <FaDiscord />
          </NavLink>
        </Socials>
        <NavLink
          href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=NZCBMP9V54G9J&source=url"
          target="_blank"
        >
          Coffee ☕
        </NavLink>
        <Link to="/source-codes">Codes</Link>
      </StyledNav>
      <hr />
    </StyledSimpleNav>
  );
};

export default SimpleNav;

const StyledSimpleNav = styled.div`
  a {
    font-size: 14px;
    text-transform: capitalize;
  }
  hr {
    margin-bottom: 3rem;
  }
`;

const StyledNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  a {
    color: #c9d1d9;
  }
`;

const Socials = styled.div`
  display: flex;

  a {
    &:nth-child(even) {
      margin: 0 1rem;

      @media (max-width: ${({ theme }) => theme.mobile}) {
        margin: 0 0.7rem;
      }
    }
  }
`;
