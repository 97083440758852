import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFound = () => {
  return (
    <>
      <Styled404>
        <h2>Ooops!!! You are lost... Error 404.</h2>
        <h2>Go Back To Safety 👇</h2>
        <Link to="/">Portfolio</Link>
        <Link to="/source-codes">Source Codes</Link>
      </Styled404>
    </>
  );
};

export default NotFound;

const Styled404 = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    margin-bottom: 0.5rem;
  }

  a {
    font-size: 15px;
    margin-top: 0.5rem;
  }
`;
