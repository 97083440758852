import { useState, useEffect } from "react";
import { createClient } from "contentful";

import styled from "styled-components";
import PostCard from "../components/source-codes/PostCard";
import SimpleNav from "../components/source-codes/SimpleNav";

const SourceCodes = () => {
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        setErr(false);
        const client = createClient({
          space: "i3pjy6wbx18f",
          accessToken: "1VvocaCaJrFCx2AuojR1P_CKTwvQfgKcnKCyykVNXlE",
        });

        const response = await client.getEntries({
          content_type: "sourceCodes",
        });

        setPosts(response.items);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setErr(true);
      }
    };

    fetchPosts();
  }, []);

  return (
    <>
      <Container>
        <StyledSourceCodes>
          <SimpleNav />
          <h1>Source Codes for Chaoo Charles YouTube Courses</h1>
          <PostList>
            {loading ? <p>Loading...</p> : null}
            {err ? (
              <Err>
                Unexpected error occured. Check your internet connection and
                then refresh the page. If this error is persistent, let me know
                via my email <span>chaoocharles@gmail.com</span>
              </Err>
            ) : null}
            {posts?.map((post, index) => (
              <PostCard key={index} post={post} />
            ))}
          </PostList>
        </StyledSourceCodes>
      </Container>
    </>
  );
};

export default SourceCodes;

const StyledSourceCodes = styled.div`
  padding: 3rem;

  h1 {
    font-family: "JetBrains Mono", monospace;
    font-size: 25px;
    margin-bottom: 1rem;
  }

  a {
    font-size: 15px;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem 0rem;
  } ;
`;

const PostList = styled.div``;

const Err = styled.p`
  color: #cd0000;
  span {
    color: #b9b9b9;
  }
`;

export const Container = styled.div`
  width: 1000px;
  max-width: 100%;
  padding: 0 5rem;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 2rem;
  } ;
`;
