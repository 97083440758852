import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { createClient } from "contentful";
import {
  FaDownload,
  FaGithub,
  FaYoutube,
  FaArrowCircleLeft,
} from "react-icons/fa";

import styled from "styled-components";
import SimpleNav from "../components/source-codes/SimpleNav";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const PostDetails = () => {
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [noPost, setNoPost] = useState(false);

  console.log(post);

  // const { title, gitHubLink, zippedFile, slug } = post.fields;

  const params = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        setErr(false);
        setNoPost(false);
        const client = createClient({
          space: "i3pjy6wbx18f",
          accessToken: "1VvocaCaJrFCx2AuojR1P_CKTwvQfgKcnKCyykVNXlE",
        });

        const response = await client.getEntries({
          content_type: "sourceCodes",
          "fields.slug": params.slug,
        });

        if (!response.items[0]) {
          setNoPost(true);
        }

        setPost(response.items[0]);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setErr(true);
        setNoPost(false);
      }
    };

    fetchPost();
  }, [params.slug]);

  return (
    <>
      <Container>
        <StyledPostDetails>
          <SimpleNav />
          {loading ? <p>Loading...</p> : null}
          {err ? (
            <Err>
              Unexpected error occured. Check your internet connection and then
              refresh the page. If this error is persistent, let me know via my
              email <span>chaoocharles@gmail.com</span>
            </Err>
          ) : null}
          {noPost ? (
            <Err>
              No source codes found. You might have visited a broken url or the
              source codes was deleted.
            </Err>
          ) : null}
          {post && (
            <>
              <ArrowLeft>
                <Link to="/source-codes">
                  <FaArrowCircleLeft />
                </Link>
              </ArrowLeft>
              <h2>{post.fields.title}</h2>
              <Description>
                {post.fields.description && (
                  <> {documentToReactComponents(post.fields.description)}</>
                )}
              </Description>
              <CustomLinks>
                {post.fields.starterFiles && (
                  <CustomLink
                    href={`https:${post.fields.starterFiles.fields.file?.url}`}
                    download
                  >
                    <FaDownload /> <span>Download Starter Files</span>
                  </CustomLink>
                )}
                <CustomLink
                  href={`https:${post.fields.zippedFile.fields.file?.url}`}
                  download
                >
                  <FaDownload /> <span>Download Source Codes</span>
                </CustomLink>

                <CustomLink href={`${post.fields.gitHubLink}`} target="_blank">
                  <FaGithub /> <span>View Code on GitHub</span>
                </CustomLink>
                {post.fields.youTubeLink && (
                  <CustomLink
                    href={`${post.fields.youTubeLink}`}
                    target="_blank"
                  >
                    <FaYoutube /> <span>Watch Course on YouTube</span>
                  </CustomLink>
                )}
              </CustomLinks>
            </>
          )}
        </StyledPostDetails>
      </Container>
    </>
  );
};

export default PostDetails;

const StyledPostDetails = styled.div`
  padding: 3rem;

  h2 {
    font-family: "Poppins", sans-serif;
    margin-bottom: 2rem;
    font-size: 20px;
  }

  a {
    font-size: 15px;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem 0;
  } ;
`;

const Description = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 15px;

  p {
    font-size: 15px;
  }
`;

const CustomLinks = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CustomLink = styled.a`
  display: flex;
  align-items: center;
  font-family: "JetBrains Mono", monospace;

  span {
    margin-left: 1rem;
  }

  &:nth-child(even) {
    margin: 1.5rem 0;
  }
`;

const Err = styled.p`
  color: #cd0000;
  span {
    color: #b9b9b9;
  }
`;

export const Container = styled.div`
  width: 1000px;
  max-width: 100%;
  padding: 0 5rem;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 2rem;
  } ;
`;

export const ArrowLeft = styled.div`
  margin-bottom: 1.5rem;
  a {
    font-size: 20px;
  }
`;
