import { FaArrowDown, FaYoutube, FaGithub, FaTwitter } from "react-icons/fa";
import { CTAButton } from "../styles/common/CTAButton.styled";
import {
  HeroContent,
  StyledArrowDown,
  StyledHeroSection,
  HeroSocialIcons,
} from "../styles/sections/HeroSection.styled";
import { Icon } from "../styles/common/Icon.styled";

export const HeroSection = () => {
  return (
    <StyledHeroSection id="hero-section">
      <HeroContent>
        <h1>Chaoo Charles</h1>
        <h3>{"{Front-End Web Developer}"}</h3>
        <p>HTML . CSS . JavaScript . React . Next.js</p>
        <div>
          <CTAButton href="#projects">Projects</CTAButton>
          <CTAButton href="#contact" primary margin="0 0 0 20px">
            Hire Me
          </CTAButton>
        </div>
      </HeroContent>
      <StyledArrowDown href="#about">
        <FaArrowDown />
      </StyledArrowDown>
      <HeroSocialIcons>
        <Icon href="https://www.youtube.com/c/chaoocharles" target="_blank">
          <FaYoutube />
        </Icon>
        <Icon href="https://github.com/chaoocharles" target="_blank">
          <FaGithub />
        </Icon>
        <Icon href="https://twitter.com/ChaooCharles" target="_blank">
          <FaTwitter />
        </Icon>
      </HeroSocialIcons>
    </StyledHeroSection>
  );
};
