import {
  AboutContent,
  StyledAboutSection,
  StyledEmoji,
  DarkCircle,
  GradientCircle,
  ImageContainer,
  StyledImage,
  StyledHeading,
} from "../styles/sections/AboutSection.styled";
import { CTAButton } from "../styles/common/CTAButton.styled";

export const AboutSection = () => {
  return (
    <>
      <StyledAboutSection id="about">
        <ImageContainer>
          <StyledImage
            src="./images/profile-pic.jpg"
            alt="Chaoo Charles"
          ></StyledImage>
          <DarkCircle />
          <GradientCircle />
        </ImageContainer>
        <AboutContent>
          <StyledHeading>
            Hello <StyledEmoji>👋</StyledEmoji>
          </StyledHeading>
          <p>
            I am Charles, a software developer <strong> based in Kenya</strong>.
            I develop full-blown web applications using the MERN Stack, but I
            mostly love working on the front-end using <strong>CSS</strong> and{" "}
            <strong>React</strong>.
          </p>
          <p>
            One of my favorite activities is to educate and mentor others in the
            field to fulfill their potential and deepen my knowledge. You can
            find more about this on my{" "}
            <a
              href="https://www.youtube.com/c/chaoocharles"
              target="_blank"
              rel="noreferrer"
            >
              YouTube Channel.
            </a>
          </p>
          <p>
            I recently published my well curated course to show you how you can
            create a portfolio site just like this one using{" "}
            <strong>React</strong> and <strong>Styled Components</strong> 💅.
          </p>
          <CTAButton
            href="https://chaoocharles.gumroad.com/l/react-portfolio-course"
            target="_blank"
          >
            My Portfolio Course
          </CTAButton>
        </AboutContent>
      </StyledAboutSection>
      <hr />
    </>
  );
};
