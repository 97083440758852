import { useState } from "react";
import useScrollDirection from "../../hooks/useScrollDirection";
import { CTAButton } from "../styles/common/CTAButton.styled";
import { NavItems } from "../../data/NavItems";
import {
  LineOne,
  LineThree,
  LineTwo,
  Logo,
  MenuIcon,
  Nav,
  NavList,
  NavItem,
  NavLink,
  ProFile,
  SocialIcons,
  SourceCodesLink,
} from "../styles/layout/Nav.styled";
import { FaYoutube, FaTwitter, FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";

export const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const scrollDirection = useScrollDirection("down");

  return (
    <>
      <Nav scrollDirection={scrollDirection}>
        <div>
          <a href="#hero-section">
            <Logo src="./images/logo.png" />
          </a>
        </div>
        <MenuIcon menuOpen={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
          <LineOne menuOpen={menuOpen} />
          <LineTwo menuOpen={menuOpen} />
          <LineThree menuOpen={menuOpen} />
        </MenuIcon>
        <NavList menuOpen={menuOpen}>
          {NavItems &&
            NavItems.map((navItem, index) => (
              <NavItem
                key={index}
                index={index}
                onClick={() => setMenuOpen(false)}
                menuOpen={menuOpen}
              >
                {navItem.isProfile ? (
                  <NavLink href="#hero-section">
                    <ProFile>
                      <img src="./images/profile-pic.jpg" alt="Chaoo Charles" />
                      Chaoo Charles
                    </ProFile>
                  </NavLink>
                ) : null}
                {navItem.text ? (
                  <NavLink href={navItem.href}>{navItem.text}</NavLink>
                ) : null}
                {navItem.hasSocialIcons ? (
                  <SocialIcons>
                    <NavLink
                      href="https://www.youtube.com/c/chaoocharles"
                      target="_blank"
                    >
                      <FaYoutube />
                    </NavLink>
                    <NavLink
                      href="https://github.com/chaoocharles"
                      target="_blank"
                    >
                      <FaGithub />
                    </NavLink>
                    <NavLink
                      href="https://twitter.com/ChaooCharles"
                      target="_blank"
                    >
                      <FaTwitter />
                    </NavLink>
                  </SocialIcons>
                ) : null}
                {navItem.isRoute ? (
                  <SourceCodesLink>
                    <Link to="/source-codes">Source Codes</Link>
                  </SourceCodesLink>
                ) : null}
                {navItem.hasCTA ? (
                  <CTAButton
                    href="https://chaoocharles.gumroad.com/l/react-portfolio-course"
                    target="_blank"
                  >
                    this.portFolio.course
                  </CTAButton>
                ) : null}
              </NavItem>
            ))}
        </NavList>
      </Nav>
    </>
  );
};
